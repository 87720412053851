
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1420px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-sm,
.gx-lg-sm {
    --bs-gutter-x: 0.33335rem;
  }

  .g-lg-sm,
.gy-lg-sm {
    --bs-gutter-y: 0.33335rem;
  }

  .g-lg-3px,
.gx-lg-3px {
    --bs-gutter-x: 3px;
  }

  .g-lg-3px,
.gy-lg-3px {
    --bs-gutter-y: 3px;
  }

  .g-lg-15px,
.gx-lg-15px {
    --bs-gutter-x: 15px;
  }

  .g-lg-15px,
.gy-lg-15px {
    --bs-gutter-y: 15px;
  }

  .g-lg-25px,
.gx-lg-25px {
    --bs-gutter-x: 25px;
  }

  .g-lg-25px,
.gy-lg-25px {
    --bs-gutter-y: 25px;
  }

  .g-lg-35px,
.gx-lg-35px {
    --bs-gutter-x: 35px;
  }

  .g-lg-35px,
.gy-lg-35px {
    --bs-gutter-y: 35px;
  }

  .g-lg-n15,
.gx-lg-n15 {
    --bs-gutter-x: -15px;
  }

  .g-lg-n15,
.gy-lg-n15 {
    --bs-gutter-y: -15px;
  }

  .g-lg-n1,
.gx-lg-n1 {
    --bs-gutter-x: -10px;
  }

  .g-lg-n1,
.gy-lg-n1 {
    --bs-gutter-y: -10px;
  }

  .g-lg-n5,
.gx-lg-n5 {
    --bs-gutter-x: -5px;
  }

  .g-lg-n5,
.gy-lg-n5 {
    --bs-gutter-y: -5px;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.6667rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.6667rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 1.3334rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 1.3334rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 2.0001rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 2.0001rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 2.6668rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 2.6668rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3.3335rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3.3335rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 4.0002rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 4.0002rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 4.6669rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 4.6669rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 5.3336rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 5.3336rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 6.0003rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 6.0003rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 6.667rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 6.667rem;
  }

  .g-lg-gutter,
.gx-lg-gutter {
    --bs-gutter-x: 2.133rem;
  }

  .g-lg-gutter,
.gy-lg-gutter {
    --bs-gutter-y: 2.133rem;
  }

  .g-lg-out-container,
.gx-lg-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }

  .g-lg-out-container,
.gy-lg-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-sm,
.gx-xl-sm {
    --bs-gutter-x: 0.33335rem;
  }

  .g-xl-sm,
.gy-xl-sm {
    --bs-gutter-y: 0.33335rem;
  }

  .g-xl-3px,
.gx-xl-3px {
    --bs-gutter-x: 3px;
  }

  .g-xl-3px,
.gy-xl-3px {
    --bs-gutter-y: 3px;
  }

  .g-xl-15px,
.gx-xl-15px {
    --bs-gutter-x: 15px;
  }

  .g-xl-15px,
.gy-xl-15px {
    --bs-gutter-y: 15px;
  }

  .g-xl-25px,
.gx-xl-25px {
    --bs-gutter-x: 25px;
  }

  .g-xl-25px,
.gy-xl-25px {
    --bs-gutter-y: 25px;
  }

  .g-xl-35px,
.gx-xl-35px {
    --bs-gutter-x: 35px;
  }

  .g-xl-35px,
.gy-xl-35px {
    --bs-gutter-y: 35px;
  }

  .g-xl-n15,
.gx-xl-n15 {
    --bs-gutter-x: -15px;
  }

  .g-xl-n15,
.gy-xl-n15 {
    --bs-gutter-y: -15px;
  }

  .g-xl-n1,
.gx-xl-n1 {
    --bs-gutter-x: -10px;
  }

  .g-xl-n1,
.gy-xl-n1 {
    --bs-gutter-y: -10px;
  }

  .g-xl-n5,
.gx-xl-n5 {
    --bs-gutter-x: -5px;
  }

  .g-xl-n5,
.gy-xl-n5 {
    --bs-gutter-y: -5px;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.6667rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.6667rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 1.3334rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 1.3334rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 2.0001rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 2.0001rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 2.6668rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 2.6668rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3.3335rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3.3335rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 4.0002rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 4.0002rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 4.6669rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 4.6669rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 5.3336rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 5.3336rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 6.0003rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 6.0003rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 6.667rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 6.667rem;
  }

  .g-xl-gutter,
.gx-xl-gutter {
    --bs-gutter-x: 2.133rem;
  }

  .g-xl-gutter,
.gy-xl-gutter {
    --bs-gutter-y: 2.133rem;
  }

  .g-xl-out-container,
.gx-xl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }

  .g-xl-out-container,
.gy-xl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-sm,
.gx-xxl-sm {
    --bs-gutter-x: 0.33335rem;
  }

  .g-xxl-sm,
.gy-xxl-sm {
    --bs-gutter-y: 0.33335rem;
  }

  .g-xxl-3px,
.gx-xxl-3px {
    --bs-gutter-x: 3px;
  }

  .g-xxl-3px,
.gy-xxl-3px {
    --bs-gutter-y: 3px;
  }

  .g-xxl-15px,
.gx-xxl-15px {
    --bs-gutter-x: 15px;
  }

  .g-xxl-15px,
.gy-xxl-15px {
    --bs-gutter-y: 15px;
  }

  .g-xxl-25px,
.gx-xxl-25px {
    --bs-gutter-x: 25px;
  }

  .g-xxl-25px,
.gy-xxl-25px {
    --bs-gutter-y: 25px;
  }

  .g-xxl-35px,
.gx-xxl-35px {
    --bs-gutter-x: 35px;
  }

  .g-xxl-35px,
.gy-xxl-35px {
    --bs-gutter-y: 35px;
  }

  .g-xxl-n15,
.gx-xxl-n15 {
    --bs-gutter-x: -15px;
  }

  .g-xxl-n15,
.gy-xxl-n15 {
    --bs-gutter-y: -15px;
  }

  .g-xxl-n1,
.gx-xxl-n1 {
    --bs-gutter-x: -10px;
  }

  .g-xxl-n1,
.gy-xxl-n1 {
    --bs-gutter-y: -10px;
  }

  .g-xxl-n5,
.gx-xxl-n5 {
    --bs-gutter-x: -5px;
  }

  .g-xxl-n5,
.gy-xxl-n5 {
    --bs-gutter-y: -5px;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.6667rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.6667rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 1.3334rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 1.3334rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 2.0001rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 2.0001rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 2.6668rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 2.6668rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3.3335rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3.3335rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 4.0002rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 4.0002rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 4.6669rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 4.6669rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 5.3336rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 5.3336rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 6.0003rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 6.0003rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 6.667rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 6.667rem;
  }

  .g-xxl-gutter,
.gx-xxl-gutter {
    --bs-gutter-x: 2.133rem;
  }

  .g-xxl-gutter,
.gy-xxl-gutter {
    --bs-gutter-y: 2.133rem;
  }

  .g-xxl-out-container,
.gx-xxl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }

  .g-xxl-out-container,
.gy-xxl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .btn-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
    font-size: 1.875rem;
  }
}


@media (min-width: 1200px) {
  .topbar a {
    color: #fff;
    letter-spacing: 1.2px;
  }

  .header_logo img {
    max-width: 85px;
  }
  .header_menu {
    width: calc(100% - 245px);
  }
  .header_menu .headmenu-wrapper {
    padding-top: 0;
  }
  .header_menu .headmenu-wrapper ul {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
  .header_menu .headmenu-wrapper ul li {
    width: auto;
    padding: 0 0 0 50px;
  }
  .header_menu .headmenu-wrapper ul li.menu-item-37 {
    display: none;
  }
  .header_menu .headmenu-wrapper ul li a {
    transition: 0.3s;
    font-size: 18px;
  }
  .header_menu .headmenu-wrapper ul li a:hover {
    color: #ed2224;
  }
  .header_search .search_toggle {
    top: 113px;
  }
}


@media (min-width: 1200px) {
  footer_address .widget_block:first-child p {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }

  .top-lg-0 {
    top: 0 !important;
  }

  .top-lg-25 {
    top: 25% !important;
  }

  .top-lg-50 {
    top: 50% !important;
  }

  .top-lg-75 {
    top: 75% !important;
  }

  .top-lg-100 {
    top: 100% !important;
  }

  .top-lg-10px {
    top: 10px !important;
  }

  .top-lg-90px {
    top: 90px !important;
  }

  .top-lg-n1 {
    top: -10px !important;
  }

  .top-lg-n2 {
    top: -20px !important;
  }

  .top-lg-3px {
    top: 3px !important;
  }

  .top-lg-60px {
    top: 60px !important;
  }

  .top-lg-70px {
    top: 70px !important;
  }

  .top-lg-80px {
    top: 80px !important;
  }

  .top-lg-100px {
    top: 100px !important;
  }

  .top-lg-13px {
    top: 13px !important;
  }

  .top-lg-15px {
    top: 15px !important;
  }

  .top-lg-n3px {
    top: -3px !important;
  }

  .top-lg-sm {
    top: 5px !important;
  }

  .bottom-lg-0 {
    bottom: 0 !important;
  }

  .bottom-lg-25 {
    bottom: 25% !important;
  }

  .bottom-lg-50 {
    bottom: 50% !important;
  }

  .bottom-lg-75 {
    bottom: 75% !important;
  }

  .bottom-lg-100 {
    bottom: 100% !important;
  }

  .bottom-lg-10px {
    bottom: 10px !important;
  }

  .bottom-lg-90px {
    bottom: 90px !important;
  }

  .bottom-lg-n1 {
    bottom: -10px !important;
  }

  .bottom-lg-n2 {
    bottom: -20px !important;
  }

  .bottom-lg-3px {
    bottom: 3px !important;
  }

  .bottom-lg-60px {
    bottom: 60px !important;
  }

  .bottom-lg-70px {
    bottom: 70px !important;
  }

  .bottom-lg-80px {
    bottom: 80px !important;
  }

  .bottom-lg-100px {
    bottom: 100px !important;
  }

  .bottom-lg-13px {
    bottom: 13px !important;
  }

  .bottom-lg-15px {
    bottom: 15px !important;
  }

  .bottom-lg-n3px {
    bottom: -3px !important;
  }

  .bottom-lg-sm {
    bottom: 5px !important;
  }

  .start-lg-0 {
    left: 0 !important;
  }

  .start-lg-25 {
    left: 25% !important;
  }

  .start-lg-50 {
    left: 50% !important;
  }

  .start-lg-75 {
    left: 75% !important;
  }

  .start-lg-100 {
    left: 100% !important;
  }

  .start-lg-10px {
    left: 10px !important;
  }

  .start-lg-90px {
    left: 90px !important;
  }

  .start-lg-n1 {
    left: -10px !important;
  }

  .start-lg-n2 {
    left: -20px !important;
  }

  .start-lg-3px {
    left: 3px !important;
  }

  .start-lg-60px {
    left: 60px !important;
  }

  .start-lg-70px {
    left: 70px !important;
  }

  .start-lg-80px {
    left: 80px !important;
  }

  .start-lg-100px {
    left: 100px !important;
  }

  .start-lg-13px {
    left: 13px !important;
  }

  .start-lg-15px {
    left: 15px !important;
  }

  .start-lg-n3px {
    left: -3px !important;
  }

  .start-lg-sm {
    left: 5px !important;
  }

  .end-lg-0 {
    right: 0 !important;
  }

  .end-lg-25 {
    right: 25% !important;
  }

  .end-lg-50 {
    right: 50% !important;
  }

  .end-lg-75 {
    right: 75% !important;
  }

  .end-lg-100 {
    right: 100% !important;
  }

  .end-lg-10px {
    right: 10px !important;
  }

  .end-lg-90px {
    right: 90px !important;
  }

  .end-lg-n1 {
    right: -10px !important;
  }

  .end-lg-n2 {
    right: -20px !important;
  }

  .end-lg-3px {
    right: 3px !important;
  }

  .end-lg-60px {
    right: 60px !important;
  }

  .end-lg-70px {
    right: 70px !important;
  }

  .end-lg-80px {
    right: 80px !important;
  }

  .end-lg-100px {
    right: 100px !important;
  }

  .end-lg-13px {
    right: 13px !important;
  }

  .end-lg-15px {
    right: 15px !important;
  }

  .end-lg-n3px {
    right: -3px !important;
  }

  .end-lg-sm {
    right: 5px !important;
  }

  .translate-middle-lg {
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-lg-x {
    transform: translateX(-50%) !important;
  }

  .translate-middle-lg-y {
    transform: translateY(-50%) !important;
  }

  .border-lg {
    border: 1px solid #d6d6d6 !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg {
    border-top: 1px solid #d6d6d6 !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-end-lg {
    border-right: 1px solid #d6d6d6 !important;
  }

  .border-end-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg {
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-start-lg {
    border-left: 1px solid #d6d6d6 !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-sm {
    width: 5px !important;
  }

  .w-lg-20px {
    width: 20px !important;
  }

  .w-lg-15px {
    width: 15px !important;
  }

  .w-lg-25px {
    width: 25px !important;
  }

  .w-lg-35px {
    width: 35px !important;
  }

  .w-lg-40px {
    width: 40px !important;
  }

  .w-lg-45px {
    width: 45px !important;
  }

  .w-lg-60px {
    width: 60px !important;
  }

  .w-lg-90px {
    width: 90px !important;
  }

  .w-lg-100px {
    width: 100px !important;
  }

  .w-lg-120px {
    width: 120px !important;
  }

  .w-lg-150px {
    width: 150px !important;
  }

  .w-lg-160px {
    width: 160px !important;
  }

  .w-lg-200px {
    width: 200px !important;
  }

  .w-lg-300px {
    width: 300px !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .maxw-lg-100 {
    max-width: 100% !important;
  }

  .maxw-lg-10 {
    max-width: 10% !important;
  }

  .maxw-lg-15 {
    max-width: 15% !important;
  }

  .maxw-lg-20 {
    max-width: 20% !important;
  }

  .maxw-lg-30 {
    max-width: 30% !important;
  }

  .maxw-lg-35 {
    max-width: 35% !important;
  }

  .maxw-lg-40 {
    max-width: 40% !important;
  }

  .maxw-lg-50 {
    max-width: 50% !important;
  }

  .maxw-lg-60 {
    max-width: 60% !important;
  }

  .maxw-lg-70 {
    max-width: 70% !important;
  }

  .maxw-lg-85 {
    max-width: 85% !important;
  }

  .maxw-lg-80 {
    max-width: 80% !important;
  }

  .maxw-lg-90 {
    max-width: 90% !important;
  }

  .maxw-lg-220 {
    max-width: 220px !important;
  }

  .maxw-lg-400 {
    max-width: 400px !important;
  }

  .maxw-lg-300 {
    max-width: 300px !important;
  }

  .maxw-lg-700 {
    max-width: 700px !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-n40 {
    height: 40px !important;
  }

  .h-lg-n49 {
    height: 49px !important;
  }

  .h-lg-15px {
    height: 15px !important;
  }

  .h-lg-n60 {
    height: 60px !important;
  }

  .h-lg-n80 {
    height: 80px !important;
  }

  .h-lg-50vh {
    height: 50vh !important;
  }

  .h-lg-70vh {
    height: 70vh !important;
  }

  .h-lg-480 {
    height: 480px !important;
  }

  .h-lg-n120 {
    height: 120px !important;
  }

  .mh-lg-350 {
    max-height: 350px !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-sm {
    gap: 0.33335rem !important;
  }

  .gap-lg-3px {
    gap: 3px !important;
  }

  .gap-lg-15px {
    gap: 15px !important;
  }

  .gap-lg-25px {
    gap: 25px !important;
  }

  .gap-lg-35px {
    gap: 35px !important;
  }

  .gap-lg-n15 {
    gap: -15px !important;
  }

  .gap-lg-n1 {
    gap: -10px !important;
  }

  .gap-lg-n5 {
    gap: -5px !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.6667rem !important;
  }

  .gap-lg-2 {
    gap: 1.3334rem !important;
  }

  .gap-lg-3 {
    gap: 2.0001rem !important;
  }

  .gap-lg-4 {
    gap: 2.6668rem !important;
  }

  .gap-lg-5 {
    gap: 3.3335rem !important;
  }

  .gap-lg-6 {
    gap: 4.0002rem !important;
  }

  .gap-lg-7 {
    gap: 4.6669rem !important;
  }

  .gap-lg-8 {
    gap: 5.3336rem !important;
  }

  .gap-lg-9 {
    gap: 6.0003rem !important;
  }

  .gap-lg-10 {
    gap: 6.667rem !important;
  }

  .gap-lg-gutter {
    gap: 2.133rem !important;
  }

  .gap-lg-out-container {
    gap: calc(50% - 50vw) !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-sm {
    margin: 0.33335rem !important;
  }

  .m-lg-3px {
    margin: 3px !important;
  }

  .m-lg-15px {
    margin: 15px !important;
  }

  .m-lg-25px {
    margin: 25px !important;
  }

  .m-lg-35px {
    margin: 35px !important;
  }

  .m-lg-n15 {
    margin: -15px !important;
  }

  .m-lg-n1 {
    margin: -10px !important;
  }

  .m-lg-n5 {
    margin: -5px !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.6667rem !important;
  }

  .m-lg-2 {
    margin: 1.3334rem !important;
  }

  .m-lg-3 {
    margin: 2.0001rem !important;
  }

  .m-lg-4 {
    margin: 2.6668rem !important;
  }

  .m-lg-5 {
    margin: 3.3335rem !important;
  }

  .m-lg-6 {
    margin: 4.0002rem !important;
  }

  .m-lg-7 {
    margin: 4.6669rem !important;
  }

  .m-lg-8 {
    margin: 5.3336rem !important;
  }

  .m-lg-9 {
    margin: 6.0003rem !important;
  }

  .m-lg-10 {
    margin: 6.667rem !important;
  }

  .m-lg-gutter {
    margin: 2.133rem !important;
  }

  .m-lg-out-container {
    margin: calc(50% - 50vw) !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }

  .mx-lg-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .mx-lg-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-lg-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .mx-lg-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }

  .mx-lg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-lg-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-lg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }

  .mx-lg-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }

  .mx-lg-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }

  .mx-lg-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }

  .mx-lg-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }

  .mx-lg-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }

  .mx-lg-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }

  .mx-lg-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }

  .mx-lg-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }

  .mx-lg-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }

  .mx-lg-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }

  .mx-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }

  .my-lg-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .my-lg-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-lg-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-lg-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-lg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-lg-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-lg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }

  .my-lg-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }

  .my-lg-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }

  .my-lg-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }

  .my-lg-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }

  .my-lg-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }

  .my-lg-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }

  .my-lg-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }

  .my-lg-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }

  .my-lg-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }

  .my-lg-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }

  .my-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-sm {
    margin-top: 0.33335rem !important;
  }

  .mt-lg-3px {
    margin-top: 3px !important;
  }

  .mt-lg-15px {
    margin-top: 15px !important;
  }

  .mt-lg-25px {
    margin-top: 25px !important;
  }

  .mt-lg-35px {
    margin-top: 35px !important;
  }

  .mt-lg-n15 {
    margin-top: -15px !important;
  }

  .mt-lg-n1 {
    margin-top: -10px !important;
  }

  .mt-lg-n5 {
    margin-top: -5px !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.6667rem !important;
  }

  .mt-lg-2 {
    margin-top: 1.3334rem !important;
  }

  .mt-lg-3 {
    margin-top: 2.0001rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.6668rem !important;
  }

  .mt-lg-5 {
    margin-top: 3.3335rem !important;
  }

  .mt-lg-6 {
    margin-top: 4.0002rem !important;
  }

  .mt-lg-7 {
    margin-top: 4.6669rem !important;
  }

  .mt-lg-8 {
    margin-top: 5.3336rem !important;
  }

  .mt-lg-9 {
    margin-top: 6.0003rem !important;
  }

  .mt-lg-10 {
    margin-top: 6.667rem !important;
  }

  .mt-lg-gutter {
    margin-top: 2.133rem !important;
  }

  .mt-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-sm {
    margin-right: 0.33335rem !important;
  }

  .me-lg-3px {
    margin-right: 3px !important;
  }

  .me-lg-15px {
    margin-right: 15px !important;
  }

  .me-lg-25px {
    margin-right: 25px !important;
  }

  .me-lg-35px {
    margin-right: 35px !important;
  }

  .me-lg-n15 {
    margin-right: -15px !important;
  }

  .me-lg-n1 {
    margin-right: -10px !important;
  }

  .me-lg-n5 {
    margin-right: -5px !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.6667rem !important;
  }

  .me-lg-2 {
    margin-right: 1.3334rem !important;
  }

  .me-lg-3 {
    margin-right: 2.0001rem !important;
  }

  .me-lg-4 {
    margin-right: 2.6668rem !important;
  }

  .me-lg-5 {
    margin-right: 3.3335rem !important;
  }

  .me-lg-6 {
    margin-right: 4.0002rem !important;
  }

  .me-lg-7 {
    margin-right: 4.6669rem !important;
  }

  .me-lg-8 {
    margin-right: 5.3336rem !important;
  }

  .me-lg-9 {
    margin-right: 6.0003rem !important;
  }

  .me-lg-10 {
    margin-right: 6.667rem !important;
  }

  .me-lg-gutter {
    margin-right: 2.133rem !important;
  }

  .me-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-sm {
    margin-bottom: 0.33335rem !important;
  }

  .mb-lg-3px {
    margin-bottom: 3px !important;
  }

  .mb-lg-15px {
    margin-bottom: 15px !important;
  }

  .mb-lg-25px {
    margin-bottom: 25px !important;
  }

  .mb-lg-35px {
    margin-bottom: 35px !important;
  }

  .mb-lg-n15 {
    margin-bottom: -15px !important;
  }

  .mb-lg-n1 {
    margin-bottom: -10px !important;
  }

  .mb-lg-n5 {
    margin-bottom: -5px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.6667rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1.3334rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 2.0001rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.6668rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3.3335rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4.0002rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4.6669rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 5.3336rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 6.0003rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 6.667rem !important;
  }

  .mb-lg-gutter {
    margin-bottom: 2.133rem !important;
  }

  .mb-lg-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-sm {
    margin-left: 0.33335rem !important;
  }

  .ms-lg-3px {
    margin-left: 3px !important;
  }

  .ms-lg-15px {
    margin-left: 15px !important;
  }

  .ms-lg-25px {
    margin-left: 25px !important;
  }

  .ms-lg-35px {
    margin-left: 35px !important;
  }

  .ms-lg-n15 {
    margin-left: -15px !important;
  }

  .ms-lg-n1 {
    margin-left: -10px !important;
  }

  .ms-lg-n5 {
    margin-left: -5px !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.6667rem !important;
  }

  .ms-lg-2 {
    margin-left: 1.3334rem !important;
  }

  .ms-lg-3 {
    margin-left: 2.0001rem !important;
  }

  .ms-lg-4 {
    margin-left: 2.6668rem !important;
  }

  .ms-lg-5 {
    margin-left: 3.3335rem !important;
  }

  .ms-lg-6 {
    margin-left: 4.0002rem !important;
  }

  .ms-lg-7 {
    margin-left: 4.6669rem !important;
  }

  .ms-lg-8 {
    margin-left: 5.3336rem !important;
  }

  .ms-lg-9 {
    margin-left: 6.0003rem !important;
  }

  .ms-lg-10 {
    margin-left: 6.667rem !important;
  }

  .ms-lg-gutter {
    margin-left: 2.133rem !important;
  }

  .ms-lg-out-container {
    margin-left: calc(50% - 50vw) !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-sm {
    padding: 0.33335rem !important;
  }

  .p-lg-3px {
    padding: 3px !important;
  }

  .p-lg-15px {
    padding: 15px !important;
  }

  .p-lg-25px {
    padding: 25px !important;
  }

  .p-lg-35px {
    padding: 35px !important;
  }

  .p-lg-n15 {
    padding: -15px !important;
  }

  .p-lg-n1 {
    padding: -10px !important;
  }

  .p-lg-n5 {
    padding: -5px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.6667rem !important;
  }

  .p-lg-2 {
    padding: 1.3334rem !important;
  }

  .p-lg-3 {
    padding: 2.0001rem !important;
  }

  .p-lg-4 {
    padding: 2.6668rem !important;
  }

  .p-lg-5 {
    padding: 3.3335rem !important;
  }

  .p-lg-6 {
    padding: 4.0002rem !important;
  }

  .p-lg-7 {
    padding: 4.6669rem !important;
  }

  .p-lg-8 {
    padding: 5.3336rem !important;
  }

  .p-lg-9 {
    padding: 6.0003rem !important;
  }

  .p-lg-10 {
    padding: 6.667rem !important;
  }

  .p-lg-gutter {
    padding: 2.133rem !important;
  }

  .p-lg-out-container {
    padding: calc(50% - 50vw) !important;
  }

  .px-lg-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }

  .px-lg-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .px-lg-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-lg-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .px-lg-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .px-lg-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }

  .px-lg-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }

  .px-lg-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }

  .px-lg-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }

  .px-lg-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }

  .px-lg-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }

  .px-lg-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }

  .px-lg-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }

  .px-lg-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }

  .px-lg-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }

  .px-lg-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }

  .px-lg-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }

  .px-lg-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }

  .px-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }

  .py-lg-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }

  .py-lg-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .py-lg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-lg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-lg-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-lg-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .py-lg-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .py-lg-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }

  .py-lg-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }

  .py-lg-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }

  .py-lg-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }

  .py-lg-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }

  .py-lg-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }

  .py-lg-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }

  .py-lg-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }

  .py-lg-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }

  .py-lg-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }

  .py-lg-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }

  .py-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }

  .pt-lg-sm {
    padding-top: 0.33335rem !important;
  }

  .pt-lg-3px {
    padding-top: 3px !important;
  }

  .pt-lg-15px {
    padding-top: 15px !important;
  }

  .pt-lg-25px {
    padding-top: 25px !important;
  }

  .pt-lg-35px {
    padding-top: 35px !important;
  }

  .pt-lg-n15 {
    padding-top: -15px !important;
  }

  .pt-lg-n1 {
    padding-top: -10px !important;
  }

  .pt-lg-n5 {
    padding-top: -5px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.6667rem !important;
  }

  .pt-lg-2 {
    padding-top: 1.3334rem !important;
  }

  .pt-lg-3 {
    padding-top: 2.0001rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.6668rem !important;
  }

  .pt-lg-5 {
    padding-top: 3.3335rem !important;
  }

  .pt-lg-6 {
    padding-top: 4.0002rem !important;
  }

  .pt-lg-7 {
    padding-top: 4.6669rem !important;
  }

  .pt-lg-8 {
    padding-top: 5.3336rem !important;
  }

  .pt-lg-9 {
    padding-top: 6.0003rem !important;
  }

  .pt-lg-10 {
    padding-top: 6.667rem !important;
  }

  .pt-lg-gutter {
    padding-top: 2.133rem !important;
  }

  .pt-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
  }

  .pe-lg-sm {
    padding-right: 0.33335rem !important;
  }

  .pe-lg-3px {
    padding-right: 3px !important;
  }

  .pe-lg-15px {
    padding-right: 15px !important;
  }

  .pe-lg-25px {
    padding-right: 25px !important;
  }

  .pe-lg-35px {
    padding-right: 35px !important;
  }

  .pe-lg-n15 {
    padding-right: -15px !important;
  }

  .pe-lg-n1 {
    padding-right: -10px !important;
  }

  .pe-lg-n5 {
    padding-right: -5px !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.6667rem !important;
  }

  .pe-lg-2 {
    padding-right: 1.3334rem !important;
  }

  .pe-lg-3 {
    padding-right: 2.0001rem !important;
  }

  .pe-lg-4 {
    padding-right: 2.6668rem !important;
  }

  .pe-lg-5 {
    padding-right: 3.3335rem !important;
  }

  .pe-lg-6 {
    padding-right: 4.0002rem !important;
  }

  .pe-lg-7 {
    padding-right: 4.6669rem !important;
  }

  .pe-lg-8 {
    padding-right: 5.3336rem !important;
  }

  .pe-lg-9 {
    padding-right: 6.0003rem !important;
  }

  .pe-lg-10 {
    padding-right: 6.667rem !important;
  }

  .pe-lg-gutter {
    padding-right: 2.133rem !important;
  }

  .pe-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
  }

  .pb-lg-sm {
    padding-bottom: 0.33335rem !important;
  }

  .pb-lg-3px {
    padding-bottom: 3px !important;
  }

  .pb-lg-15px {
    padding-bottom: 15px !important;
  }

  .pb-lg-25px {
    padding-bottom: 25px !important;
  }

  .pb-lg-35px {
    padding-bottom: 35px !important;
  }

  .pb-lg-n15 {
    padding-bottom: -15px !important;
  }

  .pb-lg-n1 {
    padding-bottom: -10px !important;
  }

  .pb-lg-n5 {
    padding-bottom: -5px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.6667rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1.3334rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 2.0001rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.6668rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3.3335rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4.0002rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 4.6669rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 5.3336rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 6.0003rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 6.667rem !important;
  }

  .pb-lg-gutter {
    padding-bottom: 2.133rem !important;
  }

  .pb-lg-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }

  .ps-lg-sm {
    padding-left: 0.33335rem !important;
  }

  .ps-lg-3px {
    padding-left: 3px !important;
  }

  .ps-lg-15px {
    padding-left: 15px !important;
  }

  .ps-lg-25px {
    padding-left: 25px !important;
  }

  .ps-lg-35px {
    padding-left: 35px !important;
  }

  .ps-lg-n15 {
    padding-left: -15px !important;
  }

  .ps-lg-n1 {
    padding-left: -10px !important;
  }

  .ps-lg-n5 {
    padding-left: -5px !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.6667rem !important;
  }

  .ps-lg-2 {
    padding-left: 1.3334rem !important;
  }

  .ps-lg-3 {
    padding-left: 2.0001rem !important;
  }

  .ps-lg-4 {
    padding-left: 2.6668rem !important;
  }

  .ps-lg-5 {
    padding-left: 3.3335rem !important;
  }

  .ps-lg-6 {
    padding-left: 4.0002rem !important;
  }

  .ps-lg-7 {
    padding-left: 4.6669rem !important;
  }

  .ps-lg-8 {
    padding-left: 5.3336rem !important;
  }

  .ps-lg-9 {
    padding-left: 6.0003rem !important;
  }

  .ps-lg-10 {
    padding-left: 6.667rem !important;
  }

  .ps-lg-gutter {
    padding-left: 2.133rem !important;
  }

  .ps-lg-out-container {
    padding-left: calc(50% - 50vw) !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .fs-lg-1 {
    font-size: 2.5rem !important;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

  .fs-lg-3 {
    font-size: 1.75rem !important;
  }

  .fs-lg-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg-5 {
    font-size: 1.25rem !important;
  }

  .fs-lg-6 {
    font-size: 1.125rem !important;
  }

  .fs-lg-7 {
    font-size: 1rem !important;
  }

  .fs-lg-lg {
    font-size: 1.875rem !important;
  }

  .fs-lg-sm {
    font-size: 0.875rem !important;
  }

  .fs-lg-xs {
    font-size: 0.75rem !important;
  }

  .fs-lg-xxs {
    font-size: 0.625rem !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }

  .top-xl-0 {
    top: 0 !important;
  }

  .top-xl-25 {
    top: 25% !important;
  }

  .top-xl-50 {
    top: 50% !important;
  }

  .top-xl-75 {
    top: 75% !important;
  }

  .top-xl-100 {
    top: 100% !important;
  }

  .top-xl-10px {
    top: 10px !important;
  }

  .top-xl-90px {
    top: 90px !important;
  }

  .top-xl-n1 {
    top: -10px !important;
  }

  .top-xl-n2 {
    top: -20px !important;
  }

  .top-xl-3px {
    top: 3px !important;
  }

  .top-xl-60px {
    top: 60px !important;
  }

  .top-xl-70px {
    top: 70px !important;
  }

  .top-xl-80px {
    top: 80px !important;
  }

  .top-xl-100px {
    top: 100px !important;
  }

  .top-xl-13px {
    top: 13px !important;
  }

  .top-xl-15px {
    top: 15px !important;
  }

  .top-xl-n3px {
    top: -3px !important;
  }

  .top-xl-sm {
    top: 5px !important;
  }

  .bottom-xl-0 {
    bottom: 0 !important;
  }

  .bottom-xl-25 {
    bottom: 25% !important;
  }

  .bottom-xl-50 {
    bottom: 50% !important;
  }

  .bottom-xl-75 {
    bottom: 75% !important;
  }

  .bottom-xl-100 {
    bottom: 100% !important;
  }

  .bottom-xl-10px {
    bottom: 10px !important;
  }

  .bottom-xl-90px {
    bottom: 90px !important;
  }

  .bottom-xl-n1 {
    bottom: -10px !important;
  }

  .bottom-xl-n2 {
    bottom: -20px !important;
  }

  .bottom-xl-3px {
    bottom: 3px !important;
  }

  .bottom-xl-60px {
    bottom: 60px !important;
  }

  .bottom-xl-70px {
    bottom: 70px !important;
  }

  .bottom-xl-80px {
    bottom: 80px !important;
  }

  .bottom-xl-100px {
    bottom: 100px !important;
  }

  .bottom-xl-13px {
    bottom: 13px !important;
  }

  .bottom-xl-15px {
    bottom: 15px !important;
  }

  .bottom-xl-n3px {
    bottom: -3px !important;
  }

  .bottom-xl-sm {
    bottom: 5px !important;
  }

  .start-xl-0 {
    left: 0 !important;
  }

  .start-xl-25 {
    left: 25% !important;
  }

  .start-xl-50 {
    left: 50% !important;
  }

  .start-xl-75 {
    left: 75% !important;
  }

  .start-xl-100 {
    left: 100% !important;
  }

  .start-xl-10px {
    left: 10px !important;
  }

  .start-xl-90px {
    left: 90px !important;
  }

  .start-xl-n1 {
    left: -10px !important;
  }

  .start-xl-n2 {
    left: -20px !important;
  }

  .start-xl-3px {
    left: 3px !important;
  }

  .start-xl-60px {
    left: 60px !important;
  }

  .start-xl-70px {
    left: 70px !important;
  }

  .start-xl-80px {
    left: 80px !important;
  }

  .start-xl-100px {
    left: 100px !important;
  }

  .start-xl-13px {
    left: 13px !important;
  }

  .start-xl-15px {
    left: 15px !important;
  }

  .start-xl-n3px {
    left: -3px !important;
  }

  .start-xl-sm {
    left: 5px !important;
  }

  .end-xl-0 {
    right: 0 !important;
  }

  .end-xl-25 {
    right: 25% !important;
  }

  .end-xl-50 {
    right: 50% !important;
  }

  .end-xl-75 {
    right: 75% !important;
  }

  .end-xl-100 {
    right: 100% !important;
  }

  .end-xl-10px {
    right: 10px !important;
  }

  .end-xl-90px {
    right: 90px !important;
  }

  .end-xl-n1 {
    right: -10px !important;
  }

  .end-xl-n2 {
    right: -20px !important;
  }

  .end-xl-3px {
    right: 3px !important;
  }

  .end-xl-60px {
    right: 60px !important;
  }

  .end-xl-70px {
    right: 70px !important;
  }

  .end-xl-80px {
    right: 80px !important;
  }

  .end-xl-100px {
    right: 100px !important;
  }

  .end-xl-13px {
    right: 13px !important;
  }

  .end-xl-15px {
    right: 15px !important;
  }

  .end-xl-n3px {
    right: -3px !important;
  }

  .end-xl-sm {
    right: 5px !important;
  }

  .translate-middle-xl {
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-xl-x {
    transform: translateX(-50%) !important;
  }

  .translate-middle-xl-y {
    transform: translateY(-50%) !important;
  }

  .border-xl {
    border: 1px solid #d6d6d6 !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl {
    border-top: 1px solid #d6d6d6 !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-end-xl {
    border-right: 1px solid #d6d6d6 !important;
  }

  .border-end-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl {
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xl {
    border-left: 1px solid #d6d6d6 !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-sm {
    width: 5px !important;
  }

  .w-xl-20px {
    width: 20px !important;
  }

  .w-xl-15px {
    width: 15px !important;
  }

  .w-xl-25px {
    width: 25px !important;
  }

  .w-xl-35px {
    width: 35px !important;
  }

  .w-xl-40px {
    width: 40px !important;
  }

  .w-xl-45px {
    width: 45px !important;
  }

  .w-xl-60px {
    width: 60px !important;
  }

  .w-xl-90px {
    width: 90px !important;
  }

  .w-xl-100px {
    width: 100px !important;
  }

  .w-xl-120px {
    width: 120px !important;
  }

  .w-xl-150px {
    width: 150px !important;
  }

  .w-xl-160px {
    width: 160px !important;
  }

  .w-xl-200px {
    width: 200px !important;
  }

  .w-xl-300px {
    width: 300px !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .maxw-xl-100 {
    max-width: 100% !important;
  }

  .maxw-xl-10 {
    max-width: 10% !important;
  }

  .maxw-xl-15 {
    max-width: 15% !important;
  }

  .maxw-xl-20 {
    max-width: 20% !important;
  }

  .maxw-xl-30 {
    max-width: 30% !important;
  }

  .maxw-xl-35 {
    max-width: 35% !important;
  }

  .maxw-xl-40 {
    max-width: 40% !important;
  }

  .maxw-xl-50 {
    max-width: 50% !important;
  }

  .maxw-xl-60 {
    max-width: 60% !important;
  }

  .maxw-xl-70 {
    max-width: 70% !important;
  }

  .maxw-xl-85 {
    max-width: 85% !important;
  }

  .maxw-xl-80 {
    max-width: 80% !important;
  }

  .maxw-xl-90 {
    max-width: 90% !important;
  }

  .maxw-xl-220 {
    max-width: 220px !important;
  }

  .maxw-xl-400 {
    max-width: 400px !important;
  }

  .maxw-xl-300 {
    max-width: 300px !important;
  }

  .maxw-xl-700 {
    max-width: 700px !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-n40 {
    height: 40px !important;
  }

  .h-xl-n49 {
    height: 49px !important;
  }

  .h-xl-15px {
    height: 15px !important;
  }

  .h-xl-n60 {
    height: 60px !important;
  }

  .h-xl-n80 {
    height: 80px !important;
  }

  .h-xl-50vh {
    height: 50vh !important;
  }

  .h-xl-70vh {
    height: 70vh !important;
  }

  .h-xl-480 {
    height: 480px !important;
  }

  .h-xl-n120 {
    height: 120px !important;
  }

  .mh-xl-350 {
    max-height: 350px !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-sm {
    gap: 0.33335rem !important;
  }

  .gap-xl-3px {
    gap: 3px !important;
  }

  .gap-xl-15px {
    gap: 15px !important;
  }

  .gap-xl-25px {
    gap: 25px !important;
  }

  .gap-xl-35px {
    gap: 35px !important;
  }

  .gap-xl-n15 {
    gap: -15px !important;
  }

  .gap-xl-n1 {
    gap: -10px !important;
  }

  .gap-xl-n5 {
    gap: -5px !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.6667rem !important;
  }

  .gap-xl-2 {
    gap: 1.3334rem !important;
  }

  .gap-xl-3 {
    gap: 2.0001rem !important;
  }

  .gap-xl-4 {
    gap: 2.6668rem !important;
  }

  .gap-xl-5 {
    gap: 3.3335rem !important;
  }

  .gap-xl-6 {
    gap: 4.0002rem !important;
  }

  .gap-xl-7 {
    gap: 4.6669rem !important;
  }

  .gap-xl-8 {
    gap: 5.3336rem !important;
  }

  .gap-xl-9 {
    gap: 6.0003rem !important;
  }

  .gap-xl-10 {
    gap: 6.667rem !important;
  }

  .gap-xl-gutter {
    gap: 2.133rem !important;
  }

  .gap-xl-out-container {
    gap: calc(50% - 50vw) !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-sm {
    margin: 0.33335rem !important;
  }

  .m-xl-3px {
    margin: 3px !important;
  }

  .m-xl-15px {
    margin: 15px !important;
  }

  .m-xl-25px {
    margin: 25px !important;
  }

  .m-xl-35px {
    margin: 35px !important;
  }

  .m-xl-n15 {
    margin: -15px !important;
  }

  .m-xl-n1 {
    margin: -10px !important;
  }

  .m-xl-n5 {
    margin: -5px !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.6667rem !important;
  }

  .m-xl-2 {
    margin: 1.3334rem !important;
  }

  .m-xl-3 {
    margin: 2.0001rem !important;
  }

  .m-xl-4 {
    margin: 2.6668rem !important;
  }

  .m-xl-5 {
    margin: 3.3335rem !important;
  }

  .m-xl-6 {
    margin: 4.0002rem !important;
  }

  .m-xl-7 {
    margin: 4.6669rem !important;
  }

  .m-xl-8 {
    margin: 5.3336rem !important;
  }

  .m-xl-9 {
    margin: 6.0003rem !important;
  }

  .m-xl-10 {
    margin: 6.667rem !important;
  }

  .m-xl-gutter {
    margin: 2.133rem !important;
  }

  .m-xl-out-container {
    margin: calc(50% - 50vw) !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }

  .mx-xl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .mx-xl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-xl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .mx-xl-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }

  .mx-xl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-xl-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-xl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }

  .mx-xl-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }

  .mx-xl-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }

  .mx-xl-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }

  .mx-xl-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }

  .mx-xl-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }

  .mx-xl-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }

  .mx-xl-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }

  .mx-xl-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }

  .mx-xl-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }

  .mx-xl-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }

  .mx-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }

  .my-xl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .my-xl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-xl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-xl-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-xl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-xl-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-xl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }

  .my-xl-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }

  .my-xl-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }

  .my-xl-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }

  .my-xl-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }

  .my-xl-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }

  .my-xl-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }

  .my-xl-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }

  .my-xl-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }

  .my-xl-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }

  .my-xl-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }

  .my-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-sm {
    margin-top: 0.33335rem !important;
  }

  .mt-xl-3px {
    margin-top: 3px !important;
  }

  .mt-xl-15px {
    margin-top: 15px !important;
  }

  .mt-xl-25px {
    margin-top: 25px !important;
  }

  .mt-xl-35px {
    margin-top: 35px !important;
  }

  .mt-xl-n15 {
    margin-top: -15px !important;
  }

  .mt-xl-n1 {
    margin-top: -10px !important;
  }

  .mt-xl-n5 {
    margin-top: -5px !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.6667rem !important;
  }

  .mt-xl-2 {
    margin-top: 1.3334rem !important;
  }

  .mt-xl-3 {
    margin-top: 2.0001rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.6668rem !important;
  }

  .mt-xl-5 {
    margin-top: 3.3335rem !important;
  }

  .mt-xl-6 {
    margin-top: 4.0002rem !important;
  }

  .mt-xl-7 {
    margin-top: 4.6669rem !important;
  }

  .mt-xl-8 {
    margin-top: 5.3336rem !important;
  }

  .mt-xl-9 {
    margin-top: 6.0003rem !important;
  }

  .mt-xl-10 {
    margin-top: 6.667rem !important;
  }

  .mt-xl-gutter {
    margin-top: 2.133rem !important;
  }

  .mt-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-sm {
    margin-right: 0.33335rem !important;
  }

  .me-xl-3px {
    margin-right: 3px !important;
  }

  .me-xl-15px {
    margin-right: 15px !important;
  }

  .me-xl-25px {
    margin-right: 25px !important;
  }

  .me-xl-35px {
    margin-right: 35px !important;
  }

  .me-xl-n15 {
    margin-right: -15px !important;
  }

  .me-xl-n1 {
    margin-right: -10px !important;
  }

  .me-xl-n5 {
    margin-right: -5px !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.6667rem !important;
  }

  .me-xl-2 {
    margin-right: 1.3334rem !important;
  }

  .me-xl-3 {
    margin-right: 2.0001rem !important;
  }

  .me-xl-4 {
    margin-right: 2.6668rem !important;
  }

  .me-xl-5 {
    margin-right: 3.3335rem !important;
  }

  .me-xl-6 {
    margin-right: 4.0002rem !important;
  }

  .me-xl-7 {
    margin-right: 4.6669rem !important;
  }

  .me-xl-8 {
    margin-right: 5.3336rem !important;
  }

  .me-xl-9 {
    margin-right: 6.0003rem !important;
  }

  .me-xl-10 {
    margin-right: 6.667rem !important;
  }

  .me-xl-gutter {
    margin-right: 2.133rem !important;
  }

  .me-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-sm {
    margin-bottom: 0.33335rem !important;
  }

  .mb-xl-3px {
    margin-bottom: 3px !important;
  }

  .mb-xl-15px {
    margin-bottom: 15px !important;
  }

  .mb-xl-25px {
    margin-bottom: 25px !important;
  }

  .mb-xl-35px {
    margin-bottom: 35px !important;
  }

  .mb-xl-n15 {
    margin-bottom: -15px !important;
  }

  .mb-xl-n1 {
    margin-bottom: -10px !important;
  }

  .mb-xl-n5 {
    margin-bottom: -5px !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.6667rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1.3334rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 2.0001rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.6668rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3.3335rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4.0002rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4.6669rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 5.3336rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 6.0003rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 6.667rem !important;
  }

  .mb-xl-gutter {
    margin-bottom: 2.133rem !important;
  }

  .mb-xl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-sm {
    margin-left: 0.33335rem !important;
  }

  .ms-xl-3px {
    margin-left: 3px !important;
  }

  .ms-xl-15px {
    margin-left: 15px !important;
  }

  .ms-xl-25px {
    margin-left: 25px !important;
  }

  .ms-xl-35px {
    margin-left: 35px !important;
  }

  .ms-xl-n15 {
    margin-left: -15px !important;
  }

  .ms-xl-n1 {
    margin-left: -10px !important;
  }

  .ms-xl-n5 {
    margin-left: -5px !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.6667rem !important;
  }

  .ms-xl-2 {
    margin-left: 1.3334rem !important;
  }

  .ms-xl-3 {
    margin-left: 2.0001rem !important;
  }

  .ms-xl-4 {
    margin-left: 2.6668rem !important;
  }

  .ms-xl-5 {
    margin-left: 3.3335rem !important;
  }

  .ms-xl-6 {
    margin-left: 4.0002rem !important;
  }

  .ms-xl-7 {
    margin-left: 4.6669rem !important;
  }

  .ms-xl-8 {
    margin-left: 5.3336rem !important;
  }

  .ms-xl-9 {
    margin-left: 6.0003rem !important;
  }

  .ms-xl-10 {
    margin-left: 6.667rem !important;
  }

  .ms-xl-gutter {
    margin-left: 2.133rem !important;
  }

  .ms-xl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-sm {
    padding: 0.33335rem !important;
  }

  .p-xl-3px {
    padding: 3px !important;
  }

  .p-xl-15px {
    padding: 15px !important;
  }

  .p-xl-25px {
    padding: 25px !important;
  }

  .p-xl-35px {
    padding: 35px !important;
  }

  .p-xl-n15 {
    padding: -15px !important;
  }

  .p-xl-n1 {
    padding: -10px !important;
  }

  .p-xl-n5 {
    padding: -5px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.6667rem !important;
  }

  .p-xl-2 {
    padding: 1.3334rem !important;
  }

  .p-xl-3 {
    padding: 2.0001rem !important;
  }

  .p-xl-4 {
    padding: 2.6668rem !important;
  }

  .p-xl-5 {
    padding: 3.3335rem !important;
  }

  .p-xl-6 {
    padding: 4.0002rem !important;
  }

  .p-xl-7 {
    padding: 4.6669rem !important;
  }

  .p-xl-8 {
    padding: 5.3336rem !important;
  }

  .p-xl-9 {
    padding: 6.0003rem !important;
  }

  .p-xl-10 {
    padding: 6.667rem !important;
  }

  .p-xl-gutter {
    padding: 2.133rem !important;
  }

  .p-xl-out-container {
    padding: calc(50% - 50vw) !important;
  }

  .px-xl-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }

  .px-xl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .px-xl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-xl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .px-xl-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .px-xl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }

  .px-xl-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }

  .px-xl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }

  .px-xl-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }

  .px-xl-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }

  .px-xl-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }

  .px-xl-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }

  .px-xl-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }

  .px-xl-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }

  .px-xl-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }

  .px-xl-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }

  .px-xl-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }

  .px-xl-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }

  .px-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }

  .py-xl-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }

  .py-xl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .py-xl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-xl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-xl-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-xl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .py-xl-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .py-xl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }

  .py-xl-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }

  .py-xl-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }

  .py-xl-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }

  .py-xl-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }

  .py-xl-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }

  .py-xl-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }

  .py-xl-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }

  .py-xl-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }

  .py-xl-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }

  .py-xl-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }

  .py-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }

  .pt-xl-sm {
    padding-top: 0.33335rem !important;
  }

  .pt-xl-3px {
    padding-top: 3px !important;
  }

  .pt-xl-15px {
    padding-top: 15px !important;
  }

  .pt-xl-25px {
    padding-top: 25px !important;
  }

  .pt-xl-35px {
    padding-top: 35px !important;
  }

  .pt-xl-n15 {
    padding-top: -15px !important;
  }

  .pt-xl-n1 {
    padding-top: -10px !important;
  }

  .pt-xl-n5 {
    padding-top: -5px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.6667rem !important;
  }

  .pt-xl-2 {
    padding-top: 1.3334rem !important;
  }

  .pt-xl-3 {
    padding-top: 2.0001rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.6668rem !important;
  }

  .pt-xl-5 {
    padding-top: 3.3335rem !important;
  }

  .pt-xl-6 {
    padding-top: 4.0002rem !important;
  }

  .pt-xl-7 {
    padding-top: 4.6669rem !important;
  }

  .pt-xl-8 {
    padding-top: 5.3336rem !important;
  }

  .pt-xl-9 {
    padding-top: 6.0003rem !important;
  }

  .pt-xl-10 {
    padding-top: 6.667rem !important;
  }

  .pt-xl-gutter {
    padding-top: 2.133rem !important;
  }

  .pt-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }

  .pe-xl-sm {
    padding-right: 0.33335rem !important;
  }

  .pe-xl-3px {
    padding-right: 3px !important;
  }

  .pe-xl-15px {
    padding-right: 15px !important;
  }

  .pe-xl-25px {
    padding-right: 25px !important;
  }

  .pe-xl-35px {
    padding-right: 35px !important;
  }

  .pe-xl-n15 {
    padding-right: -15px !important;
  }

  .pe-xl-n1 {
    padding-right: -10px !important;
  }

  .pe-xl-n5 {
    padding-right: -5px !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.6667rem !important;
  }

  .pe-xl-2 {
    padding-right: 1.3334rem !important;
  }

  .pe-xl-3 {
    padding-right: 2.0001rem !important;
  }

  .pe-xl-4 {
    padding-right: 2.6668rem !important;
  }

  .pe-xl-5 {
    padding-right: 3.3335rem !important;
  }

  .pe-xl-6 {
    padding-right: 4.0002rem !important;
  }

  .pe-xl-7 {
    padding-right: 4.6669rem !important;
  }

  .pe-xl-8 {
    padding-right: 5.3336rem !important;
  }

  .pe-xl-9 {
    padding-right: 6.0003rem !important;
  }

  .pe-xl-10 {
    padding-right: 6.667rem !important;
  }

  .pe-xl-gutter {
    padding-right: 2.133rem !important;
  }

  .pe-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }

  .pb-xl-sm {
    padding-bottom: 0.33335rem !important;
  }

  .pb-xl-3px {
    padding-bottom: 3px !important;
  }

  .pb-xl-15px {
    padding-bottom: 15px !important;
  }

  .pb-xl-25px {
    padding-bottom: 25px !important;
  }

  .pb-xl-35px {
    padding-bottom: 35px !important;
  }

  .pb-xl-n15 {
    padding-bottom: -15px !important;
  }

  .pb-xl-n1 {
    padding-bottom: -10px !important;
  }

  .pb-xl-n5 {
    padding-bottom: -5px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.6667rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 1.3334rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 2.0001rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.6668rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3.3335rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4.0002rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 4.6669rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 5.3336rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 6.0003rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 6.667rem !important;
  }

  .pb-xl-gutter {
    padding-bottom: 2.133rem !important;
  }

  .pb-xl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }

  .ps-xl-sm {
    padding-left: 0.33335rem !important;
  }

  .ps-xl-3px {
    padding-left: 3px !important;
  }

  .ps-xl-15px {
    padding-left: 15px !important;
  }

  .ps-xl-25px {
    padding-left: 25px !important;
  }

  .ps-xl-35px {
    padding-left: 35px !important;
  }

  .ps-xl-n15 {
    padding-left: -15px !important;
  }

  .ps-xl-n1 {
    padding-left: -10px !important;
  }

  .ps-xl-n5 {
    padding-left: -5px !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.6667rem !important;
  }

  .ps-xl-2 {
    padding-left: 1.3334rem !important;
  }

  .ps-xl-3 {
    padding-left: 2.0001rem !important;
  }

  .ps-xl-4 {
    padding-left: 2.6668rem !important;
  }

  .ps-xl-5 {
    padding-left: 3.3335rem !important;
  }

  .ps-xl-6 {
    padding-left: 4.0002rem !important;
  }

  .ps-xl-7 {
    padding-left: 4.6669rem !important;
  }

  .ps-xl-8 {
    padding-left: 5.3336rem !important;
  }

  .ps-xl-9 {
    padding-left: 6.0003rem !important;
  }

  .ps-xl-10 {
    padding-left: 6.667rem !important;
  }

  .ps-xl-gutter {
    padding-left: 2.133rem !important;
  }

  .ps-xl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .fs-xl-1 {
    font-size: 2.5rem !important;
  }

  .fs-xl-2 {
    font-size: 2rem !important;
  }

  .fs-xl-3 {
    font-size: 1.75rem !important;
  }

  .fs-xl-4 {
    font-size: 1.5rem !important;
  }

  .fs-xl-5 {
    font-size: 1.25rem !important;
  }

  .fs-xl-6 {
    font-size: 1.125rem !important;
  }

  .fs-xl-7 {
    font-size: 1rem !important;
  }

  .fs-xl-lg {
    font-size: 1.875rem !important;
  }

  .fs-xl-sm {
    font-size: 0.875rem !important;
  }

  .fs-xl-xs {
    font-size: 0.75rem !important;
  }

  .fs-xl-xxs {
    font-size: 0.625rem !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: sticky !important;
  }

  .top-xxl-0 {
    top: 0 !important;
  }

  .top-xxl-25 {
    top: 25% !important;
  }

  .top-xxl-50 {
    top: 50% !important;
  }

  .top-xxl-75 {
    top: 75% !important;
  }

  .top-xxl-100 {
    top: 100% !important;
  }

  .top-xxl-10px {
    top: 10px !important;
  }

  .top-xxl-90px {
    top: 90px !important;
  }

  .top-xxl-n1 {
    top: -10px !important;
  }

  .top-xxl-n2 {
    top: -20px !important;
  }

  .top-xxl-3px {
    top: 3px !important;
  }

  .top-xxl-60px {
    top: 60px !important;
  }

  .top-xxl-70px {
    top: 70px !important;
  }

  .top-xxl-80px {
    top: 80px !important;
  }

  .top-xxl-100px {
    top: 100px !important;
  }

  .top-xxl-13px {
    top: 13px !important;
  }

  .top-xxl-15px {
    top: 15px !important;
  }

  .top-xxl-n3px {
    top: -3px !important;
  }

  .top-xxl-sm {
    top: 5px !important;
  }

  .bottom-xxl-0 {
    bottom: 0 !important;
  }

  .bottom-xxl-25 {
    bottom: 25% !important;
  }

  .bottom-xxl-50 {
    bottom: 50% !important;
  }

  .bottom-xxl-75 {
    bottom: 75% !important;
  }

  .bottom-xxl-100 {
    bottom: 100% !important;
  }

  .bottom-xxl-10px {
    bottom: 10px !important;
  }

  .bottom-xxl-90px {
    bottom: 90px !important;
  }

  .bottom-xxl-n1 {
    bottom: -10px !important;
  }

  .bottom-xxl-n2 {
    bottom: -20px !important;
  }

  .bottom-xxl-3px {
    bottom: 3px !important;
  }

  .bottom-xxl-60px {
    bottom: 60px !important;
  }

  .bottom-xxl-70px {
    bottom: 70px !important;
  }

  .bottom-xxl-80px {
    bottom: 80px !important;
  }

  .bottom-xxl-100px {
    bottom: 100px !important;
  }

  .bottom-xxl-13px {
    bottom: 13px !important;
  }

  .bottom-xxl-15px {
    bottom: 15px !important;
  }

  .bottom-xxl-n3px {
    bottom: -3px !important;
  }

  .bottom-xxl-sm {
    bottom: 5px !important;
  }

  .start-xxl-0 {
    left: 0 !important;
  }

  .start-xxl-25 {
    left: 25% !important;
  }

  .start-xxl-50 {
    left: 50% !important;
  }

  .start-xxl-75 {
    left: 75% !important;
  }

  .start-xxl-100 {
    left: 100% !important;
  }

  .start-xxl-10px {
    left: 10px !important;
  }

  .start-xxl-90px {
    left: 90px !important;
  }

  .start-xxl-n1 {
    left: -10px !important;
  }

  .start-xxl-n2 {
    left: -20px !important;
  }

  .start-xxl-3px {
    left: 3px !important;
  }

  .start-xxl-60px {
    left: 60px !important;
  }

  .start-xxl-70px {
    left: 70px !important;
  }

  .start-xxl-80px {
    left: 80px !important;
  }

  .start-xxl-100px {
    left: 100px !important;
  }

  .start-xxl-13px {
    left: 13px !important;
  }

  .start-xxl-15px {
    left: 15px !important;
  }

  .start-xxl-n3px {
    left: -3px !important;
  }

  .start-xxl-sm {
    left: 5px !important;
  }

  .end-xxl-0 {
    right: 0 !important;
  }

  .end-xxl-25 {
    right: 25% !important;
  }

  .end-xxl-50 {
    right: 50% !important;
  }

  .end-xxl-75 {
    right: 75% !important;
  }

  .end-xxl-100 {
    right: 100% !important;
  }

  .end-xxl-10px {
    right: 10px !important;
  }

  .end-xxl-90px {
    right: 90px !important;
  }

  .end-xxl-n1 {
    right: -10px !important;
  }

  .end-xxl-n2 {
    right: -20px !important;
  }

  .end-xxl-3px {
    right: 3px !important;
  }

  .end-xxl-60px {
    right: 60px !important;
  }

  .end-xxl-70px {
    right: 70px !important;
  }

  .end-xxl-80px {
    right: 80px !important;
  }

  .end-xxl-100px {
    right: 100px !important;
  }

  .end-xxl-13px {
    right: 13px !important;
  }

  .end-xxl-15px {
    right: 15px !important;
  }

  .end-xxl-n3px {
    right: -3px !important;
  }

  .end-xxl-sm {
    right: 5px !important;
  }

  .translate-middle-xxl {
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-xxl-x {
    transform: translateX(-50%) !important;
  }

  .translate-middle-xxl-y {
    transform: translateY(-50%) !important;
  }

  .border-xxl {
    border: 1px solid #d6d6d6 !important;
  }

  .border-xxl-0 {
    border: 0 !important;
  }

  .border-top-xxl {
    border-top: 1px solid #d6d6d6 !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-end-xxl {
    border-right: 1px solid #d6d6d6 !important;
  }

  .border-end-xxl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xxl {
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }

  .border-start-xxl {
    border-left: 1px solid #d6d6d6 !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-sm {
    width: 5px !important;
  }

  .w-xxl-20px {
    width: 20px !important;
  }

  .w-xxl-15px {
    width: 15px !important;
  }

  .w-xxl-25px {
    width: 25px !important;
  }

  .w-xxl-35px {
    width: 35px !important;
  }

  .w-xxl-40px {
    width: 40px !important;
  }

  .w-xxl-45px {
    width: 45px !important;
  }

  .w-xxl-60px {
    width: 60px !important;
  }

  .w-xxl-90px {
    width: 90px !important;
  }

  .w-xxl-100px {
    width: 100px !important;
  }

  .w-xxl-120px {
    width: 120px !important;
  }

  .w-xxl-150px {
    width: 150px !important;
  }

  .w-xxl-160px {
    width: 160px !important;
  }

  .w-xxl-200px {
    width: 200px !important;
  }

  .w-xxl-300px {
    width: 300px !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-15 {
    width: 15% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-35 {
    width: 35% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .maxw-xxl-100 {
    max-width: 100% !important;
  }

  .maxw-xxl-10 {
    max-width: 10% !important;
  }

  .maxw-xxl-15 {
    max-width: 15% !important;
  }

  .maxw-xxl-20 {
    max-width: 20% !important;
  }

  .maxw-xxl-30 {
    max-width: 30% !important;
  }

  .maxw-xxl-35 {
    max-width: 35% !important;
  }

  .maxw-xxl-40 {
    max-width: 40% !important;
  }

  .maxw-xxl-50 {
    max-width: 50% !important;
  }

  .maxw-xxl-60 {
    max-width: 60% !important;
  }

  .maxw-xxl-70 {
    max-width: 70% !important;
  }

  .maxw-xxl-85 {
    max-width: 85% !important;
  }

  .maxw-xxl-80 {
    max-width: 80% !important;
  }

  .maxw-xxl-90 {
    max-width: 90% !important;
  }

  .maxw-xxl-220 {
    max-width: 220px !important;
  }

  .maxw-xxl-400 {
    max-width: 400px !important;
  }

  .maxw-xxl-300 {
    max-width: 300px !important;
  }

  .maxw-xxl-700 {
    max-width: 700px !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .h-xxl-n40 {
    height: 40px !important;
  }

  .h-xxl-n49 {
    height: 49px !important;
  }

  .h-xxl-15px {
    height: 15px !important;
  }

  .h-xxl-n60 {
    height: 60px !important;
  }

  .h-xxl-n80 {
    height: 80px !important;
  }

  .h-xxl-50vh {
    height: 50vh !important;
  }

  .h-xxl-70vh {
    height: 70vh !important;
  }

  .h-xxl-480 {
    height: 480px !important;
  }

  .h-xxl-n120 {
    height: 120px !important;
  }

  .mh-xxl-350 {
    max-height: 350px !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-sm {
    gap: 0.33335rem !important;
  }

  .gap-xxl-3px {
    gap: 3px !important;
  }

  .gap-xxl-15px {
    gap: 15px !important;
  }

  .gap-xxl-25px {
    gap: 25px !important;
  }

  .gap-xxl-35px {
    gap: 35px !important;
  }

  .gap-xxl-n15 {
    gap: -15px !important;
  }

  .gap-xxl-n1 {
    gap: -10px !important;
  }

  .gap-xxl-n5 {
    gap: -5px !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.6667rem !important;
  }

  .gap-xxl-2 {
    gap: 1.3334rem !important;
  }

  .gap-xxl-3 {
    gap: 2.0001rem !important;
  }

  .gap-xxl-4 {
    gap: 2.6668rem !important;
  }

  .gap-xxl-5 {
    gap: 3.3335rem !important;
  }

  .gap-xxl-6 {
    gap: 4.0002rem !important;
  }

  .gap-xxl-7 {
    gap: 4.6669rem !important;
  }

  .gap-xxl-8 {
    gap: 5.3336rem !important;
  }

  .gap-xxl-9 {
    gap: 6.0003rem !important;
  }

  .gap-xxl-10 {
    gap: 6.667rem !important;
  }

  .gap-xxl-gutter {
    gap: 2.133rem !important;
  }

  .gap-xxl-out-container {
    gap: calc(50% - 50vw) !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-sm {
    margin: 0.33335rem !important;
  }

  .m-xxl-3px {
    margin: 3px !important;
  }

  .m-xxl-15px {
    margin: 15px !important;
  }

  .m-xxl-25px {
    margin: 25px !important;
  }

  .m-xxl-35px {
    margin: 35px !important;
  }

  .m-xxl-n15 {
    margin: -15px !important;
  }

  .m-xxl-n1 {
    margin: -10px !important;
  }

  .m-xxl-n5 {
    margin: -5px !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.6667rem !important;
  }

  .m-xxl-2 {
    margin: 1.3334rem !important;
  }

  .m-xxl-3 {
    margin: 2.0001rem !important;
  }

  .m-xxl-4 {
    margin: 2.6668rem !important;
  }

  .m-xxl-5 {
    margin: 3.3335rem !important;
  }

  .m-xxl-6 {
    margin: 4.0002rem !important;
  }

  .m-xxl-7 {
    margin: 4.6669rem !important;
  }

  .m-xxl-8 {
    margin: 5.3336rem !important;
  }

  .m-xxl-9 {
    margin: 6.0003rem !important;
  }

  .m-xxl-10 {
    margin: 6.667rem !important;
  }

  .m-xxl-gutter {
    margin: 2.133rem !important;
  }

  .m-xxl-out-container {
    margin: calc(50% - 50vw) !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }

  .mx-xxl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .mx-xxl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-xxl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .mx-xxl-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }

  .mx-xxl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-xxl-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-xxl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }

  .mx-xxl-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }

  .mx-xxl-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }

  .mx-xxl-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }

  .mx-xxl-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }

  .mx-xxl-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }

  .mx-xxl-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }

  .mx-xxl-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }

  .mx-xxl-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }

  .mx-xxl-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }

  .mx-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }

  .my-xxl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .my-xxl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-xxl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-xxl-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-xxl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-xxl-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-xxl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }

  .my-xxl-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }

  .my-xxl-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }

  .my-xxl-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }

  .my-xxl-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }

  .my-xxl-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }

  .my-xxl-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }

  .my-xxl-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }

  .my-xxl-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }

  .my-xxl-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }

  .my-xxl-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }

  .my-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-sm {
    margin-top: 0.33335rem !important;
  }

  .mt-xxl-3px {
    margin-top: 3px !important;
  }

  .mt-xxl-15px {
    margin-top: 15px !important;
  }

  .mt-xxl-25px {
    margin-top: 25px !important;
  }

  .mt-xxl-35px {
    margin-top: 35px !important;
  }

  .mt-xxl-n15 {
    margin-top: -15px !important;
  }

  .mt-xxl-n1 {
    margin-top: -10px !important;
  }

  .mt-xxl-n5 {
    margin-top: -5px !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.6667rem !important;
  }

  .mt-xxl-2 {
    margin-top: 1.3334rem !important;
  }

  .mt-xxl-3 {
    margin-top: 2.0001rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2.6668rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3.3335rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4.0002rem !important;
  }

  .mt-xxl-7 {
    margin-top: 4.6669rem !important;
  }

  .mt-xxl-8 {
    margin-top: 5.3336rem !important;
  }

  .mt-xxl-9 {
    margin-top: 6.0003rem !important;
  }

  .mt-xxl-10 {
    margin-top: 6.667rem !important;
  }

  .mt-xxl-gutter {
    margin-top: 2.133rem !important;
  }

  .mt-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-sm {
    margin-right: 0.33335rem !important;
  }

  .me-xxl-3px {
    margin-right: 3px !important;
  }

  .me-xxl-15px {
    margin-right: 15px !important;
  }

  .me-xxl-25px {
    margin-right: 25px !important;
  }

  .me-xxl-35px {
    margin-right: 35px !important;
  }

  .me-xxl-n15 {
    margin-right: -15px !important;
  }

  .me-xxl-n1 {
    margin-right: -10px !important;
  }

  .me-xxl-n5 {
    margin-right: -5px !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.6667rem !important;
  }

  .me-xxl-2 {
    margin-right: 1.3334rem !important;
  }

  .me-xxl-3 {
    margin-right: 2.0001rem !important;
  }

  .me-xxl-4 {
    margin-right: 2.6668rem !important;
  }

  .me-xxl-5 {
    margin-right: 3.3335rem !important;
  }

  .me-xxl-6 {
    margin-right: 4.0002rem !important;
  }

  .me-xxl-7 {
    margin-right: 4.6669rem !important;
  }

  .me-xxl-8 {
    margin-right: 5.3336rem !important;
  }

  .me-xxl-9 {
    margin-right: 6.0003rem !important;
  }

  .me-xxl-10 {
    margin-right: 6.667rem !important;
  }

  .me-xxl-gutter {
    margin-right: 2.133rem !important;
  }

  .me-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-sm {
    margin-bottom: 0.33335rem !important;
  }

  .mb-xxl-3px {
    margin-bottom: 3px !important;
  }

  .mb-xxl-15px {
    margin-bottom: 15px !important;
  }

  .mb-xxl-25px {
    margin-bottom: 25px !important;
  }

  .mb-xxl-35px {
    margin-bottom: 35px !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -15px !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -10px !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -5px !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.6667rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 1.3334rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 2.0001rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2.6668rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3.3335rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4.0002rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4.6669rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 5.3336rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 6.0003rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 6.667rem !important;
  }

  .mb-xxl-gutter {
    margin-bottom: 2.133rem !important;
  }

  .mb-xxl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-sm {
    margin-left: 0.33335rem !important;
  }

  .ms-xxl-3px {
    margin-left: 3px !important;
  }

  .ms-xxl-15px {
    margin-left: 15px !important;
  }

  .ms-xxl-25px {
    margin-left: 25px !important;
  }

  .ms-xxl-35px {
    margin-left: 35px !important;
  }

  .ms-xxl-n15 {
    margin-left: -15px !important;
  }

  .ms-xxl-n1 {
    margin-left: -10px !important;
  }

  .ms-xxl-n5 {
    margin-left: -5px !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.6667rem !important;
  }

  .ms-xxl-2 {
    margin-left: 1.3334rem !important;
  }

  .ms-xxl-3 {
    margin-left: 2.0001rem !important;
  }

  .ms-xxl-4 {
    margin-left: 2.6668rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3.3335rem !important;
  }

  .ms-xxl-6 {
    margin-left: 4.0002rem !important;
  }

  .ms-xxl-7 {
    margin-left: 4.6669rem !important;
  }

  .ms-xxl-8 {
    margin-left: 5.3336rem !important;
  }

  .ms-xxl-9 {
    margin-left: 6.0003rem !important;
  }

  .ms-xxl-10 {
    margin-left: 6.667rem !important;
  }

  .ms-xxl-gutter {
    margin-left: 2.133rem !important;
  }

  .ms-xxl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-sm {
    padding: 0.33335rem !important;
  }

  .p-xxl-3px {
    padding: 3px !important;
  }

  .p-xxl-15px {
    padding: 15px !important;
  }

  .p-xxl-25px {
    padding: 25px !important;
  }

  .p-xxl-35px {
    padding: 35px !important;
  }

  .p-xxl-n15 {
    padding: -15px !important;
  }

  .p-xxl-n1 {
    padding: -10px !important;
  }

  .p-xxl-n5 {
    padding: -5px !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.6667rem !important;
  }

  .p-xxl-2 {
    padding: 1.3334rem !important;
  }

  .p-xxl-3 {
    padding: 2.0001rem !important;
  }

  .p-xxl-4 {
    padding: 2.6668rem !important;
  }

  .p-xxl-5 {
    padding: 3.3335rem !important;
  }

  .p-xxl-6 {
    padding: 4.0002rem !important;
  }

  .p-xxl-7 {
    padding: 4.6669rem !important;
  }

  .p-xxl-8 {
    padding: 5.3336rem !important;
  }

  .p-xxl-9 {
    padding: 6.0003rem !important;
  }

  .p-xxl-10 {
    padding: 6.667rem !important;
  }

  .p-xxl-gutter {
    padding: 2.133rem !important;
  }

  .p-xxl-out-container {
    padding: calc(50% - 50vw) !important;
  }

  .px-xxl-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }

  .px-xxl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .px-xxl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-xxl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .px-xxl-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .px-xxl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }

  .px-xxl-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }

  .px-xxl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }

  .px-xxl-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }

  .px-xxl-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }

  .px-xxl-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }

  .px-xxl-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }

  .px-xxl-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }

  .px-xxl-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }

  .px-xxl-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }

  .px-xxl-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }

  .px-xxl-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }

  .px-xxl-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }

  .px-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }

  .py-xxl-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }

  .py-xxl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .py-xxl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-xxl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-xxl-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-xxl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .py-xxl-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .py-xxl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }

  .py-xxl-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }

  .py-xxl-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }

  .py-xxl-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }

  .py-xxl-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }

  .py-xxl-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }

  .py-xxl-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }

  .py-xxl-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }

  .py-xxl-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }

  .py-xxl-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }

  .py-xxl-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }

  .py-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }

  .pt-xxl-sm {
    padding-top: 0.33335rem !important;
  }

  .pt-xxl-3px {
    padding-top: 3px !important;
  }

  .pt-xxl-15px {
    padding-top: 15px !important;
  }

  .pt-xxl-25px {
    padding-top: 25px !important;
  }

  .pt-xxl-35px {
    padding-top: 35px !important;
  }

  .pt-xxl-n15 {
    padding-top: -15px !important;
  }

  .pt-xxl-n1 {
    padding-top: -10px !important;
  }

  .pt-xxl-n5 {
    padding-top: -5px !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.6667rem !important;
  }

  .pt-xxl-2 {
    padding-top: 1.3334rem !important;
  }

  .pt-xxl-3 {
    padding-top: 2.0001rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2.6668rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3.3335rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4.0002rem !important;
  }

  .pt-xxl-7 {
    padding-top: 4.6669rem !important;
  }

  .pt-xxl-8 {
    padding-top: 5.3336rem !important;
  }

  .pt-xxl-9 {
    padding-top: 6.0003rem !important;
  }

  .pt-xxl-10 {
    padding-top: 6.667rem !important;
  }

  .pt-xxl-gutter {
    padding-top: 2.133rem !important;
  }

  .pt-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }

  .pe-xxl-sm {
    padding-right: 0.33335rem !important;
  }

  .pe-xxl-3px {
    padding-right: 3px !important;
  }

  .pe-xxl-15px {
    padding-right: 15px !important;
  }

  .pe-xxl-25px {
    padding-right: 25px !important;
  }

  .pe-xxl-35px {
    padding-right: 35px !important;
  }

  .pe-xxl-n15 {
    padding-right: -15px !important;
  }

  .pe-xxl-n1 {
    padding-right: -10px !important;
  }

  .pe-xxl-n5 {
    padding-right: -5px !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.6667rem !important;
  }

  .pe-xxl-2 {
    padding-right: 1.3334rem !important;
  }

  .pe-xxl-3 {
    padding-right: 2.0001rem !important;
  }

  .pe-xxl-4 {
    padding-right: 2.6668rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3.3335rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4.0002rem !important;
  }

  .pe-xxl-7 {
    padding-right: 4.6669rem !important;
  }

  .pe-xxl-8 {
    padding-right: 5.3336rem !important;
  }

  .pe-xxl-9 {
    padding-right: 6.0003rem !important;
  }

  .pe-xxl-10 {
    padding-right: 6.667rem !important;
  }

  .pe-xxl-gutter {
    padding-right: 2.133rem !important;
  }

  .pe-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }

  .pb-xxl-sm {
    padding-bottom: 0.33335rem !important;
  }

  .pb-xxl-3px {
    padding-bottom: 3px !important;
  }

  .pb-xxl-15px {
    padding-bottom: 15px !important;
  }

  .pb-xxl-25px {
    padding-bottom: 25px !important;
  }

  .pb-xxl-35px {
    padding-bottom: 35px !important;
  }

  .pb-xxl-n15 {
    padding-bottom: -15px !important;
  }

  .pb-xxl-n1 {
    padding-bottom: -10px !important;
  }

  .pb-xxl-n5 {
    padding-bottom: -5px !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.6667rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 1.3334rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 2.0001rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2.6668rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3.3335rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4.0002rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 4.6669rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 5.3336rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 6.0003rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 6.667rem !important;
  }

  .pb-xxl-gutter {
    padding-bottom: 2.133rem !important;
  }

  .pb-xxl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }

  .ps-xxl-sm {
    padding-left: 0.33335rem !important;
  }

  .ps-xxl-3px {
    padding-left: 3px !important;
  }

  .ps-xxl-15px {
    padding-left: 15px !important;
  }

  .ps-xxl-25px {
    padding-left: 25px !important;
  }

  .ps-xxl-35px {
    padding-left: 35px !important;
  }

  .ps-xxl-n15 {
    padding-left: -15px !important;
  }

  .ps-xxl-n1 {
    padding-left: -10px !important;
  }

  .ps-xxl-n5 {
    padding-left: -5px !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.6667rem !important;
  }

  .ps-xxl-2 {
    padding-left: 1.3334rem !important;
  }

  .ps-xxl-3 {
    padding-left: 2.0001rem !important;
  }

  .ps-xxl-4 {
    padding-left: 2.6668rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3.3335rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4.0002rem !important;
  }

  .ps-xxl-7 {
    padding-left: 4.6669rem !important;
  }

  .ps-xxl-8 {
    padding-left: 5.3336rem !important;
  }

  .ps-xxl-9 {
    padding-left: 6.0003rem !important;
  }

  .ps-xxl-10 {
    padding-left: 6.667rem !important;
  }

  .ps-xxl-gutter {
    padding-left: 2.133rem !important;
  }

  .ps-xxl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .fs-xxl-1 {
    font-size: 2.5rem !important;
  }

  .fs-xxl-2 {
    font-size: 2rem !important;
  }

  .fs-xxl-3 {
    font-size: 1.75rem !important;
  }

  .fs-xxl-4 {
    font-size: 1.5rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-6 {
    font-size: 1.125rem !important;
  }

  .fs-xxl-7 {
    font-size: 1rem !important;
  }

  .fs-xxl-lg {
    font-size: 1.875rem !important;
  }

  .fs-xxl-sm {
    font-size: 0.875rem !important;
  }

  .fs-xxl-xs {
    font-size: 0.75rem !important;
  }

  .fs-xxl-xxs {
    font-size: 0.625rem !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg {
    font-size: 1.875rem !important;
  }
}


@media (min-width: 1200px) {
  body {
    margin-top: 113px;
  }
  body .swiper-button-prev,
body .swiper-button-next {
    transform: translateY(-50%);
  }
  body .swiper-button-prev:after,
body .swiper-button-next:after {
    font-size: 33px !important;
    color: #a41a1f;
  }
  body .swiper-button-prev {
    left: 20px !important;
  }
  body .swiper-button-next {
    right: 20px !important;
  }

  main {
    min-height: calc(100vh - 230px);
  }
}